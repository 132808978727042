<template>
    <div class="container">
        <slot name="registrationType"></slot>
        <h1 class="mt-0 mb-5">{{$t('workHours.title')}}</h1>
        <work-hours-form
            @submit="onSubmit"
            @cancel="onCancel"
        />
    </div>
</template>

<script>
import WorkHoursForm from './Components/WorkHoursForm.vue'

export default {
    name: 'WorkHoursPage',
    components: {
        WorkHoursForm
    },
    mounted () {
        this.$nextTick(() => {
            setTimeout(() => {
                window.scrollTo(0, 0)
            }, 200)
        })
    },
    methods: {
        onSubmit () {
            this.$router.push({ name: 'documents' })
        },
        onCancel () {
            this.$router.push({ name: 'references' })
        }
    }
}
</script>
