<template>
    <b-form
        @submit.prevent="onSubmit"
        class="form-sm"
    >
        <p class="text-bold text-aspena-blue">
            {{$t('workHours.daysHeading')}}
        </p>

        <div class="row align-items-center">
            <div class="col-12 col-md-4 col-lg-2  align-self-center">
                <b-form-checkbox
                    v-model="monday"
                    :id="'workHours.monday'"
                >
                    {{$t('workHours.monday')}}
                </b-form-checkbox>
            </div>
            <div class="col-12 col-md-6 col-lg-6">
                <div class="row" v-if="monday">
                    <div class="col-12 col-md-6">
                        <form-input
                            v-model="mondayFrom"
                            :id="'workHours.mondayFrom'"
                            :label="$t('workHours.from')"
                            :validation="$v.mondayFrom"
                            :validation-messages="{ time: $t('workHours.invalidTimeFormat') }"
                            :formatter="formatTime"
                            :placeholder="'hh:mm'"
                        />
                    </div>
                    <div class="col-12 col-md-6">
                        <form-input
                            v-model="mondayTo"
                            :id="'workHours.mondayTo'"
                            :label="$t('workHours.to')"
                            :validation="$v.mondayTo"
                            :validation-messages="{ time: $t('workHours.invalidTimeFormat') }"
                            :formatter="formatTime"
                            :placeholder="'hh:mm'"
                        />
                    </div>
                </div>
                <p class="mb-1" v-else>
                    {{$t('workHours.closed')}}
                </p>
            </div>
        </div>

        <div class="row align-items-center">
            <div class="col-12 col-md-4 col-lg-2 align-self-center">
                <b-form-checkbox
                    v-model="tuesday"
                    :id="'workHours.tuesday'"
                >
                    {{$t('workHours.tuesday')}}
                </b-form-checkbox>
            </div>
            <div :class="{ 'col-12 col-md-4': true, 'col-lg-6': tuesday, 'col-lg-2 col-xl-1': !tuesday }">
                <div class="row" v-if="tuesday">
                    <div class="col-12 col-md-6">
                        <form-input
                            v-model="tuesdayFrom"
                            :id="'workHours.tuesdayFrom'"
                            :label="$t('workHours.from')"
                            :validation="$v.tuesdayFrom"
                            :validation-messages="{ time: $t('workHours.invalidTimeFormat') }"
                            :formatter="formatTime"
                            :placeholder="'hh:mm'"
                        />
                    </div>
                    <div class="col-12 col-md-6">
                        <form-input
                            v-model="tuesdayTo"
                            :id="'workHours.tuesdayTo'"
                            :label="$t('workHours.to')"
                            :validation="$v.tuesdayTo"
                            :validation-messages="{ time: $t('workHours.invalidTimeFormat') }"
                            :formatter="formatTime"
                            :placeholder="'hh:mm'"
                        />
                    </div>
                </div>
                <p class="mb-1" v-else>
                    {{$t('workHours.closed')}}
                </p>
            </div>
            <div class="col-12 col-md-4 col-lg-4" v-if="monday">
                <aspena-btn
                    :disabled="$v.mondayFrom.$invalid || $v.mondayTo.$invalid"
                    variant="aspena-blue"
                    @click.prevent="onSetSame('tuesday')"
                    size="sm"
                >
                    {{$t('workHours.sameAsPrevious')}}
                </aspena-btn>
            </div>
        </div>

        <div class="row align-items-center">
            <div class="col-12 col-md-4 col-lg-2  align-self-center">
                <b-form-checkbox
                    v-model="wednesday"
                    :id="'workHours.wednesday'"
                >
                    {{$t('workHours.wednesday')}}
                </b-form-checkbox>
            </div>
            <div :class="{ 'col-12 col-md-4': true, 'col-lg-6': wednesday, 'col-lg-2 col-xl-1': !wednesday }">
                <div class="row" v-if="wednesday">
                    <div class="col-12 col-md-6">
                        <form-input
                            v-model="wednesdayFrom"
                            :id="'workHours.wednesday'"
                            :label="$t('workHours.from')"
                            :validation="$v.wednesdayFrom"
                            :validation-messages="{ time: $t('workHours.invalidTimeFormat') }"
                            :formatter="formatTime"
                            :placeholder="'hh:mm'"
                        />
                    </div>
                    <div class="col-12 col-md-6">
                        <form-input
                            v-model="wednesdayTo"
                            :id="'workHours.wednesdayTo'"
                            :label="$t('workHours.to')"
                            :validation="$v.wednesdayTo"
                            :validation-messages="{ time: $t('workHours.invalidTimeFormat') }"
                            :formatter="formatTime"
                            :placeholder="'hh:mm'"
                        />
                    </div>
                </div>
                <p class="mb-1" v-else>
                    {{$t('workHours.closed')}}
                </p>
            </div>
            <div class="col-12 col-md-4 col-lg-4" v-if="tuesday">
                <aspena-btn
                    :disabled="$v.tuesdayFrom.$invalid || $v.tuesdayTo.$invalid"
                    variant="aspena-blue"
                    @click.prevent="onSetSame('wednesday')"
                    size="sm"
                >
                    {{$t('workHours.sameAsPrevious')}}
                </aspena-btn>
            </div>
        </div>

        <div class="row align-items-center">
            <div class="col-12 col-md-4 col-lg-2  align-self-center">
                <b-form-checkbox
                    v-model="thursday"
                    :id="'workHours.thursday'"
                >
                    {{$t('workHours.thursday')}}
                </b-form-checkbox>
            </div>
            <div :class="{ 'col-12 col-md-4': true, 'col-lg-6': thursday, 'col-lg-2 col-xl-1': !thursday }">
                <div class="row" v-if="thursday">
                    <div class="col-12 col-md-6">
                        <form-input
                            v-model="thursdayFrom"
                            :id="'workHours.thursdayFrom'"
                            :label="$t('workHours.from')"
                            :validation="$v.thursdayFrom"
                            :validation-messages="{ time: $t('workHours.invalidTimeFormat') }"
                            :formatter="formatTime"
                            :placeholder="'hh:mm'"
                        />
                    </div>
                    <div class="col-12 col-md-6">
                        <form-input
                            v-model="thursdayTo"
                            :id="'workHours.thursdayTo'"
                            :label="$t('workHours.to')"
                            :validation="$v.thursdayTo"
                            :validation-messages="{ time: $t('workHours.invalidTimeFormat') }"
                            :formatter="formatTime"
                            :placeholder="'hh:mm'"
                        />
                    </div>
                </div>
                <p class="mb-1" v-else>
                    {{$t('workHours.closed')}}
                </p>
            </div>
            <div class="col-12 col-md-4 col-lg-4" v-if="wednesday">
                <aspena-btn
                    :disabled="$v.wednesdayFrom.$invalid || $v.wednesdayTo.$invalid"
                    variant="aspena-blue"
                    @click.prevent="onSetSame('thursday')"
                    size="sm"
                >
                    {{$t('workHours.sameAsPrevious')}}
                </aspena-btn>
            </div>
        </div>

        <div class="row align-items-center">
            <div class="col-12 col-md-4 col-lg-2  align-self-center">
                <b-form-checkbox
                    v-model="friday"
                    :id="'workHours.friday'"
                >
                    {{$t('workHours.friday')}}
                </b-form-checkbox>
            </div>
            <div :class="{ 'col-12 col-md-4': true, 'col-lg-6': friday, 'col-lg-2 col-xl-1': !friday }">
                <div class="row" v-if="friday">
                    <div class="col-12 col-md-6">
                        <form-input
                            v-model="fridayFrom"
                            :id="'workHours.fridayFrom'"
                            :label="$t('workHours.from')"
                            :validation="$v.fridayFrom"
                            :validation-messages="{ time: $t('workHours.invalidTimeFormat') }"
                            :formatter="formatTime"
                            :placeholder="'hh:mm'"
                        />
                    </div>
                    <div class="col-12 col-md-6">
                        <form-input
                            v-model="fridayTo"
                            :id="'workHours.fridayTo'"
                            :label="$t('workHours.to')"
                            :validation="$v.fridayTo"
                            :validation-messages="{ time: $t('workHours.invalidTimeFormat') }"
                            :formatter="formatTime"
                            :placeholder="'hh:mm'"
                        />
                    </div>
                </div>
                <p class="mb-1" v-else>
                    {{$t('workHours.closed')}}
                </p>
            </div>
            <div class="col-12 col-md-4 col-lg-4" v-if="thursday">
                <aspena-btn
                    :disabled="$v.thursdayFrom.$invalid || $v.thursdayTo.$invalid"
                    variant="aspena-blue"
                    @click.prevent="onSetSame('friday')"
                    size="sm"
                >
                    {{$t('workHours.sameAsPrevious')}}
                </aspena-btn>
            </div>
        </div>

        <div class="row align-items-center">
            <div class="col-12 col-md-4 col-lg-2  align-self-center">
                <b-form-checkbox
                    v-model="saturday"
                    :id="'workHours.saturday'"
                >
                    {{$t('workHours.saturday')}}
                </b-form-checkbox>
            </div>
            <div :class="{ 'col-12 col-md-4': true, 'col-lg-6': saturday, 'col-lg-2 col-xl-1': !saturday }">
                <div class="row" v-if="saturday">
                    <div class="col-12 col-md-6">
                        <form-input
                            v-model="saturdayFrom"
                            :id="'workHours.saturdayFrom'"
                            :label="$t('workHours.from')"
                            :validation="$v.saturdayFrom"
                            :validation-messages="{ time: $t('workHours.invalidTimeFormat') }"
                            :formatter="formatTime"
                            :placeholder="'hh:mm'"
                        />
                    </div>
                    <div class="col-12 col-md-6">
                        <form-input
                            v-model="saturdayTo"
                            :id="'workHours.saturdayTo'"
                            :label="$t('workHours.to')"
                            :validation="$v.saturdayTo"
                            :validation-messages="{ time: $t('workHours.invalidTimeFormat') }"
                            :formatter="formatTime"
                            :placeholder="'hh:mm'"
                        />
                    </div>
                </div>
                <p class="mb-1" v-else>
                    {{$t('workHours.closed')}}
                </p>
            </div>
            <div class="col-12 col-md-4 col-lg-4" v-if="friday">
                <aspena-btn
                    :disabled="$v.fridayFrom.$invalid || $v.fridayTo.$invalid"
                    variant="aspena-blue"
                    @click.prevent="onSetSame('saturday')"
                    size="sm"
                >
                    {{$t('workHours.sameAsPrevious')}}
                </aspena-btn>
            </div>
        </div>

        <div class="row align-items-center">
            <div class="col-12 col-md-4 col-lg-2  align-self-center">
                <b-form-checkbox
                    v-model="sunday"
                    :id="'workHours.sunday'"
                >
                    {{$t('workHours.sunday')}}
                </b-form-checkbox>
            </div>
            <div :class="{ 'col-12 col-md-4': true, 'col-lg-6': sunday, 'col-lg-2 col-xl-1': !sunday }">
                <div class="row" v-if="sunday">
                    <div class="col-12 col-md-6">
                        <form-input
                            v-model="sundayFrom"
                            :id="'workHours.sundayFrom'"
                            :label="$t('workHours.from')"
                            :validation="$v.sundayFrom"
                            :validation-messages="{ time: $t('workHours.invalidTimeFormat') }"
                            :formatter="formatTime"
                            :placeholder="'hh:mm'"
                        />
                    </div>
                    <div class="col-12 col-md-6">
                        <form-input
                            v-model="sundayTo"
                            :id="'workHours.sundayTo'"
                            :label="$t('workHours.to')"
                            :validation="$v.sundayTo"
                            :validation-messages="{ time: $t('workHours.invalidTimeFormat') }"
                            :formatter="formatTime"
                            :placeholder="'hh:mm'"
                        />
                    </div>
                </div>
                <p class="mb-1" v-else>
                    {{$t('workHours.closed')}}
                </p>
            </div>
            <div class="col-12 col-md-4 col-lg-4" v-if="saturday">
                <aspena-btn
                    :disabled="$v.saturdayFrom.$invalid || $v.saturdayTo.$invalid"
                    variant="aspena-blue"
                    @click.prevent="onSetSame('sunday')"
                    size="sm"
                >
                    {{$t('workHours.sameAsPrevious')}}
                </aspena-btn>
            </div>
        </div>

        <b-form-checkbox
            v-model="holidays"
            class="mt-4"
        >
            {{$t('workHours.holidays')}}
        </b-form-checkbox>

        <div class="mt-4 text-right">
            <aspena-btn
                class="mr-3"
                variant="aspena-blue"
                @click.prevent="onCancel"
            >
                {{$t('workHours.cancel')}}
            </aspena-btn>

            <aspena-btn
                class="mr-3"
                variant="aspena-orange"
                type="submit"
            >
                {{$t('workHours.submit')}}
            </aspena-btn>
        </div>
    </b-form>
</template>

<script>
import { WorkHours } from '../../../DTO'
import { requiredIf } from 'vuelidate/lib/validators'

const time = (value) => {
    if (value) {
        const hours = parseInt(`${value}`.replace(/\D/ig, '').substr(0, 2))
        const minutes = parseInt(`${value}`.replace(/\D/ig, '').substr(2, 2))
        return (!isNaN(hours) && !isNaN(minutes) && hours <= 23 && minutes <= 59)
    }
    return true
}

export default {
    name: 'WorkHoursForm',
    data () {
        return {
            ...((new WorkHours(this.$store.state.userData.workHours || {})).toObject())
        }
    },
    computed: {
        timeZoneOptions () {
            return []
        },
        anyDayChecked () {
            return this.monday || this.tuesday || this.wednesday || this.thursday || this.friday || this.saturday || this.sunday
        }
    },
    methods: {
        onSetSame (dayName) {
            switch (dayName) {
                case 'tuesday':
                    this.tuesday = true
                    this.tuesdayFrom = `${this.mondayFrom}`
                    this.tuesdayTo = `${this.mondayTo}`
                    break
                case 'wednesday':
                    this.wednesday = true
                    this.wednesdayFrom = `${this.tuesdayFrom}`
                    this.wednesdayTo = `${this.tuesdayTo}`
                    break
                case 'thursday':
                    this.thursday = true
                    this.thursdayFrom = `${this.wednesdayFrom}`
                    this.thursdayTo = `${this.wednesdayTo}`
                    break
                case 'friday':
                    this.friday = true
                    this.fridayFrom = `${this.thursdayFrom}`
                    this.fridayTo = `${this.thursdayTo}`
                    break
                case 'saturday':
                    this.saturday = true
                    this.saturdayFrom = `${this.fridayFrom}`
                    this.saturdayTo = `${this.fridayTo}`
                    break
                case 'sunday':
                    this.sunday = true
                    this.sundayFrom = `${this.saturdayFrom}`
                    this.sundayTo = `${this.saturdayTo}`
                    break
            }
        },
        clearUnchecked () {
            if (!this.monday) {
                this.mondayFrom = null
                this.mondayTo = null
            }
            if (!this.tuesday) {
                this.tuesdayFrom = null
                this.tuesdayTo = null
            }
            if (!this.wednesday) {
                this.wednesdayFrom = null
                this.wednesdayTo = null
            }
            if (!this.thursday) {
                this.thursdayFrom = null
                this.thursdayTo = null
            }
            if (!this.friday) {
                this.fridayFrom = null
                this.fridayTo = null
            }
            if (!this.saturday) {
                this.saturdayFrom = null
                this.saturdayTo = null
            }
            if (!this.sunday) {
                this.sundayFrom = null
                this.sundayTo = null
            }
        },
        onSubmit () {
            this.clearUnchecked()
            if (this.$v.$invalid) {
                this.$notify.error(this.$t('workHours.formInvalidMessage'))
                return this.$v.$touch()
            }
            if (!this.anyDayChecked) {
                return this.$notify.error(this.$t('workHours.selectAtLeastOne'))
            }
            const data = new WorkHours(JSON.parse(JSON.stringify(this.$data)))
            this.$store.commit('mergeData', data)
            this.$emit('submit', data)
        },
        onCancel () {
            this.$emit('cancel')
        },
        formatTime (value) {
            if (value) {
                const numeric = `${value}`.replace(/\D/ig, '').substr(0, 4)
                let hours = parseInt(numeric.substr(0, 2))
                let minutes = parseInt(numeric.substr(2, 2))
                if (isNaN(hours)) {
                    hours = 0
                }
                if (isNaN(minutes)) {
                    minutes = 0
                }
                return `${(hours < 10) ? '0' : ''}${hours}:${(minutes < 10) ? '0' : ''}${minutes}`
            }
            return value
        }
    },
    validations: {
        mondayFrom: {
            required: requiredIf((model) => {
                return model.monday
            }),
            time
        },
        mondayTo: {
            required: requiredIf((model) => {
                return model.monday
            }),
            time
        },
        tuesdayFrom: {
            required: requiredIf((model) => {
                return model.tuesday
            }),
            time
        },
        tuesdayTo: {
            required: requiredIf((model) => {
                return model.tuesday
            }),
            time
        },
        wednesdayFrom: {
            required: requiredIf((model) => {
                return model.wednesday
            }),
            time
        },
        wednesdayTo: {
            required: requiredIf((model) => {
                return model.wednesday
            }),
            time
        },
        thursdayFrom: {
            required: requiredIf((model) => {
                return model.thursday
            }),
            time
        },
        thursdayTo: {
            required: requiredIf((model) => {
                return model.thursday
            }),
            time
        },
        fridayFrom: {
            required: requiredIf((model) => {
                return model.friday
            }),
            time
        },
        fridayTo: {
            required: requiredIf((model) => {
                return model.friday
            }),
            time
        },
        saturdayFrom: {
            required: requiredIf((model) => {
                return model.saturday
            }),
            time
        },
        saturdayTo: {
            required: requiredIf((model) => {
                return model.saturday
            }),
            time
        },
        sundayFrom: {
            required: requiredIf((model) => {
                return model.sunday
            }),
            time
        },
        sundayTo: {
            required: requiredIf((model) => {
                return model.sunday
            }),
            time
        }
    }
}
</script>
